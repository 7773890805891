var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-bar"},[_c('div',{staticClass:"tab-bar-fixed h5w"},_vm._l((_vm.orgInfo.h5AppList),function(item,i){return (item.type == 2)?_c('div',{key:'h5AppList2' + i,staticClass:"item"},[(_vm.isHttp(item.targetUrl))?_c('a',{attrs:{"href":item.targetUrl}},[_c('div',{staticClass:"icon-box"},[_c('i',{style:(`background-image:url(${_vm.requestImgUrl(
            _vm.url === item.targetUrl ? item.iconSelect : item.iconSelectUn
          )});`)})]),_c('p',{style:(`color: ${
            _vm.url === item.targetUrl ? item.activeCss : item.unActiveCss
          }`)},[_vm._v(_vm._s(item.name))])]):_c('router-link',{attrs:{"data-url":item.targetUrl,"to":item.targetUrl}},[_c('div',{staticClass:"icon-box"},[_c('i',{style:(`background-image:url(${_vm.requestImgUrl(
            _vm.url === item.targetUrl ? item.iconSelect : item.iconSelectUn
          )});`)}),(item.targetUrl == '/shopping/car' && _vm.goodsNum > 0)?_c('mt-badge',{staticClass:"badge",attrs:{"color":"#eb3030","size":"small"}},[_vm._v(_vm._s(_vm.goodsNum))]):_vm._e()],1),_c('p',{style:(`color: ${
            _vm.url === item.targetUrl ? item.activeCss : item.unActiveCss
          }`)},[_vm._v(_vm._s(item.name))])])],1):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }